import React, { useState } from "react";
import { Card, Row, Typography, Col, Button } from "antd";
import usecustomStyles from "../../Common/customStyles";
import styled from "styled-components";
import burst from "../../assets/images/burst.svg";
import swirl from "../../assets/images/swirls.svg";
import jetpackLogo from "../../assets/images/logoLarge.png";
import laptop from "../../assets/images/laptop.png";
import jetpackJ from "../../assets/images/jetpackJ.svg";

// actions
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import withRouter from "../../Common/withRouter";
import { ChevronRight } from "lucide-react";

const customStyles = usecustomStyles();

const StyledWrapper = styled.div`
  height: 145vh; // Full viewport height
  background-color: ${({ theme }) =>
    theme.token.authbgcolor}; // Assuming a theme provider is used

  .auth-card {
    min-width: 550px; // Minimum width of the card
    min-height: 500px; // Minimum height of the card
    max-width: 550px; // Maximum width of the card
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  //when mouse over button change color and increase size
  .ant-btn-primary {
    &:hover {
      background-color: #000;
      border-color: #000;
      color: #fff;
      transform: scale(1.1);
    }
  }

  @media (max-width: 1020px) {
    background-color: #6fffaf;
    height: 100vh; // Full viewport height

    .auth-card {
      min-width: 100%; // Full width on small screens
    }

    .auth-burst-image {
      display: none; // Hide the image on small screens
    }

    .auth-page-background {
      width: 100%; // Full width on small screens
    }
  }
`;

const Signin = (props) => {
  // page title
  document.title = "JetPack - Launching your business to new heights";

  const dispatch = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state) => state.Account,
    (state) => state.Login,
    (account, login) => ({
      user: account.user,
      error: login.error,
      loading: login.loading,
      errorMsg: login.errorMsg,
    })
  );

  const { user, error, loading, errorMsg } = useSelector(selectAccountAndLogin);

  const [isLoading, setLoading] = useState(loading);
  const [state, setState] = useState("login");
  const [userLogin, setUserLogin] = useState([]);

  const { Text, Title, Link } = Typography;

  const isMobile = window.innerWidth < 768;

  const FullscreenOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #6fffaf; /* Example background color with opacity */
    z-index: 9999; /* Ensure it is above other elements */
  `;

  return (
    <React.Fragment>
      {isMobile ? (
        <FullscreenOverlay>
          <div
            style={{
              position: "fixed",
              top: "20%",
              right: 0,
              zIndex: 9999,
              left: -20,
              textAlign: "center",
            }}
          >
            <img
              src={jetpackLogo}
              alt="Jetpack Logo"
              style={{
                width: "50%",
                height: "50%",
                objectFit: "contain",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>

          <div
            style={{
              position: "fixed",
              top: "30%",
              width: "100%",
              zIndex: 9000,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                maxWidth: "80%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Row
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Text
                  style={{
                    fontSize: 38,
                    fontWeight: 800,
                    color: "black",
                  }}
                >
                  Connecting founders, investors and advisors
                </Text>
              </Row>
              <Row
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 22,

                    color: "black",
                  }}
                >
                  Jetpack is an AI-powered platform that helps Founders secure
                  investment and helps investors source the best deals.
                </Text>
              </Row>
              <Row>
                <Button
                  onClick={() => {
                    window.open("https://app.gojetpack.co.uk/");
                  }}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    marginTop: 50,
                    width: 250,
                    height: 55,
                    background: "#000",
                    borderColor: "#000",
                    display: "flex", // Enable flexbox
                    justifyContent: "center", // Center content horizontally
                    alignItems: "center", // Center content vertically
                    gap: "8px", // Optional: add space between text and icon
                    color: "#fff",
                    fontSize: 23,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  Try Jetpack <ChevronRight size={20} />
                </Button>
              </Row>
            </div>
          </div>

          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 9000,
            }}
          >
            <svg
              class="sc-oTmZL kfNTWi"
              preserveAspectRatio="xMaxYMax meet"
              fill="none"
              height="160"
              viewBox="0 0 320 160"
              width="320"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="m320 142.836v-142.836h-247.5377c17.8347 34.7411 44.6347 60.244 84.9067 63.1172 68.901 4.9158 91.963 29.7342 111.186 50.4208 13.88 14.936 25.758 27.719 51.445 29.298z"
                fill="rgb(255, 249, 239)"
                fill-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div
            style={{
              position: "fixed",
              bottom: -10,
              left: 0,
              zIndex: 9000,
            }}
          >
            <svg
              class="sc-pbIaG ebFstP"
              preserveAspectRatio="xMinYMin meet"
              fill="none"
              height="160"
              viewBox="0 0 320 160"
              width="320"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="m234.731 160h-234.731v-119.5242c24.1638 2.5816 36.0047 15.3366 49.7332 30.125 19.9456 21.4853 43.8756 47.2622 115.3688 52.3682 29.72 2.123 52.369 16.134 69.629 37.031z"
                fill="rgb(255, 249, 239)"
                fill-rule="evenodd"
              ></path>
            </svg>
          </div>
        </FullscreenOverlay>
      ) : (
        <>
          <img
            src={jetpackJ}
            alt="logo"
            style={{
              position: "absolute",
              right: "3%",
              bottom: "3%",
              height: "120px",
            }}
          />
          <StyledWrapper className="auth-page-wrapper">
            <Row
              style={{
                position: "absolute",
                width: "100%",
                height: "101%",
                overflow: "hidden",
              }}
            >
              <svg
                viewBox="0 0 100 100"
                style={{ position: "absolute", overflow: "hidden" }}
              >
                <rect
                  className="auth-page-background"
                  width="60%"
                  height="100vh"
                  style={{ fill: "#6FFFAF" }}
                />
              </svg>
              <Col
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={swirl}
                  alt="logo"
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "40%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Col>
              {/* <svg
            viewBox="0 0 100 100"
            style={{
              position: "absolute",
              overflow: "hidden",
              right: 0,
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            <rect
              className="auth-page-background"
              width="40%"
              height="100vh"
              style={{ fill: "#FFFFFF" }}
            />
          </svg> */}
              <img
                className="auth-burst-image"
                src={burst}
                alt="logo"
                style={{
                  position: "absolute",
                  bottom: "-23%",
                  left: "-8%",
                  width: "33%",
                  overflow: "hidden",
                }}
              />
            </Row>
            <Row
              style={{
                justify: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Col
                xs={24}
                xl={12}
                style={{
                  height: 568,
                  marginLeft: 70,
                  marginTop: "10%",
                  marginBottom: "auto",
                }}
              >
                <Card
                  style={{
                    paddingLeft: 25,
                    paddingRight: 15,
                    marginLeft: "auto",
                    marginRight: "auto",
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      fontWeight: 200,
                      marginTop: 20,
                      marginLeft: 40,
                      top: "-80px",
                      width: "80%",
                      position: "absolute",
                    }}
                  >
                    Launching Spring 2024
                  </Text>
                  <Row>
                    <img src={jetpackLogo} alt="logo" style={{ height: 50 }} />
                  </Row>
                  <Row>
                    <Text
                      style={{
                        fontSize: "80px",
                        color: "#000",
                        fontWeight: 800,
                        marginTop: 20,
                        marginLeft: 40,
                        width: "80%",
                        lineHeight: "1.2",
                      }}
                    >
                      Connecting founders, investors and advisors
                    </Text>
                  </Row>
                  <Row>
                    <Text
                      style={{
                        fontSize: "30px",
                        color: "#000",
                        fontWeight: 400,
                        marginTop: 20,
                        marginLeft: 40,
                        width: "80%",
                      }}
                    >
                      Jetpack is an AI-powered platform that helps Founders
                      secure investment and helps investors source the best
                      deals.
                    </Text>
                  </Row>
                  <Row>
                    <Button
                      onClick={() => {
                        window.open("https://app.gojetpack.co.uk/");
                      }}
                      type="primary"
                      style={{
                        width: 250,
                        marginLeft: 40,
                        height: 55,
                        marginTop: 90,
                        background: "#000",
                        borderColor: "#000",
                        display: "flex", // Enable flexbox
                        justifyContent: "center", // Center content horizontally
                        alignItems: "center", // Center content vertically
                        gap: "8px", // Optional: add space between text and icon
                        color: "#fff",
                        fontSize: 23,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      Try Jetpack <ChevronRight size={20} />
                    </Button>
                  </Row>
                </Card>
              </Col>
              <Col
                xs={24}
                xl={11}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: "auto",
                  marginTop: "10%",
                  marginBottom: "auto",
                  transition: "all 0.3s ease-in-out",
                  overflow: "visible",
                }}
              >
                {window.innerWidth > 1200 && (
                  <img
                    src={laptop}
                    alt="logo"
                    style={{
                      position: "absolute",
                      width: isMobile ? "70%" : "100%",
                      right: -8,
                      top: 0,
                    }}
                  />
                )}

                {/* <Card
              style={{
                paddingLeft: 25,
                paddingRight: 15,
                maxWidth: 500,
                marginLeft: "auto",
                marginRight: "auto",
                transition: "all 0.3s ease-in-out",
                marginTop:
                  state === "signup" ? -30 : state === "forgot" ? 75 : 0,
              }}
            ></Card> */}
              </Col>
            </Row>
          </StyledWrapper>
        </>
      )}
    </React.Fragment>
  );
};

export default withRouter(Signin);
