import { Navigate } from "react-router-dom";
import Signin from "../pages/Authentication/login";

const authProtectedRoutes = [{ path: "/", exact: true, component: <Signin /> }];

const publicRoutes = [
  { path: "/", component: <Signin /> },
  { path: "*", component: <Signin /> },
];

export { publicRoutes, authProtectedRoutes };
